<template>
  <div>
    <div class="div-companies content-wrapper">
        <header-title
          title="Pop-Up" 
          iconTitle="nav-icon fas fa-bell font-color-custom"
        />

        <modal-configs
          v-if="showNotification"
          :selectedNotification="selectedNotification"
          @back="back()"
        />

        <list-notifications
          v-else
          @create_notification="createNotification()"
          @select_notification="selectNotification($event)"
        />
    </div>
  </div>
</template>

<script>

export default {

  components: {
    "modal-configs": () => import("./components/ModalConfigs.vue"),
    "header-title": () => import("./components/HeaderTitle.vue"),
    "list-notifications": () => import("./components/ListNotifications.vue")
  },

  data() {
    return {
      file: '',
      selectedNotification: [],
      showNotification: false
    };
  },

  watch: {},

  methods: {
    /**
     * Metodo que seleciona uma notificação
     */
    selectNotification: function (notification) {
      this.selectedNotification = notification;
      this.showNotification = true;
    },

    /**
     * Metodo que muda para a tela de criar notificação
     */
    createNotification: function () {
      this.selectedNotification = [];
      this.showNotification = true;
    },

    /**
     * Metodo que muda para a tela de listar notificações
     */
    back: function () {
      this.selectedNotification = [];
      this.showNotification = false;
    },
  },

  created() {
    document.title = "Tallos Admin - Módulo CS";
  }
};
</script>

<style scoped>
.div-companies {
  background-color: #fff;
}

.badge {
  width: 100px;
}

.bg-voip {
  background: #9c27b0;
  color: #fff;
}

.lupa-style {
  position: absolute;
  right: 10px;
  top: 8px;
  color: #0008;
  cursor: pointer;
}

.input-search {
  border-radius: 3px !important;
}

.select-review-companies {
  width: 15px !important;
  color: #0009;
  background: #fff;
  border: none;
  outline: none !important;
}

.td-last-accsess {
	font-weight: 500;
	text-align: start;
}

.span-engaged-disengaged {
  display: flex;
  justify-content: center;
  align-items: center;
}

.engaged {
	font-weight: 600;
	font-size: 14px;
	color: rgb(40,167,69);
}

.disengaged {
	font-weight: 600;
	font-size: 14px;
	color: rgb(245, 81, 31);
}

.dropdown-menu li {
  outline: none;
  border: none;
  background: white;
  color: #0652DD;
  font-weight: 600;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #ddd;
}

.title-height {
  height: 600px;
}

.spinner-size {
   max-height: 20px; 
   max-width: 20px;
}

.container-upload-image {
  width: 300px;
}

.title-upload-image {
  font-size: .7rem;
}

.button-select-image {
  font-size: .8rem;
}

.alert-upload-image {
  font-size: .7rem;
}

.button-upload-image {
  width: 80px;
}

.modal-preview {
  width: 500px;
  height: 500px;
}
</style>